body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0f0f0f;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.download-page {
  margin: 0 auto;
  padding-bottom: 120px;
  max-width: 980px;

  .mobile_show {
    display: none;
  }

  .d_head h2 {
    position: absolute;
    left: 0;
    bottom: 29px;
    color: #d4c6a9;
    font-size: 40px;
    overflow: hidden;
    opacity: 0;
  
    span {
      float: left;
      display: block;
    }
  
    .eng_title {
      line-height: 14px;
      margin-top: 17px;
      margin-left: 16px;
      font-size: 16px;
      color: #93743d;
      letter-spacing: 4px;
    }
  }
}

.download-page .d_section {
  .sec1_top {
    width: 100%;
    margin: 90px 0 0;
    padding-left: 19px;
    margin-left: -19px;
    position: relative;

    .downs {
      margin: 0 auto;

      a {
      display: block;
      }

      img {
        max-width: 100%;
      }
    }
  }

  .sec1_cont h4 {
    color: #d4c6a9;
    font-size: 26px;
    margin-top: 50px;
    padding-bottom: 20px;
    border-bottom: 2px solid #4d3b1f;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
}

.download-page .d_section .sec1_contInner {
  table {
    border-collapse: collapse;
    width: 100%;
    line-height: 24px;

    thead {
      background-color: #212121;

      tr {
        font-size: 16px;

        th {
          padding: 13px 0;
          border-right: 1px solid #342b20;
          font-weight: normal;
          }
        }
      }
    }

    tbody tr {
      border-bottom: 1px solid #342b20;

      th {
        font-weight: normal;
        border-right: 1px solid #342b20;
      }

      td {
        border-right: 1px solid #342b20;
        text-align: center;
        padding: 16px 0;

        &:nth-last-child(1) {
          border-right: 0;
      }
    }
  }

  &.pc {
    display: block;
  }

  &.mo {
    display: none;
  }
}

.download-page .d_section.section2 {
  .sec1_cont > ol > li {
    > p {
      font-size: 16px;
      color: #d4c6a9;
      line-height: 26px;
      margin: 37px 0 16px;

      span.num {
        font-size: 22px;
        color: #8a6c3c;
        margin-right: 10px;
      }
    }

    .step_wrap {
      border: 1px solid #342b20;
      background-color: #161616;
      text-align: center;
    }

    .step_wrap.step1 {
      height: 250px;

        h5 {
          font-size: 32px;
          color: #d4c6a9;
          margin: 48px 0 15px;
        }

        p {
          font-size: 16px;
          color: #978e79;
          margin-bottom: 34px;
        }

        .download_btn img {
          max-width: 100%;
        }

        .download_btn {
          display: block;
          margin: 0 auto;
          border: 1px solid #bc9e58;
          font-size: 18px;
          width: 178px;
          color: #bc9e58;
          height: 48px;
          line-height: 48px;
          position: relative;

          &:after {
            content: "";
            display: block;
            position: absolute;
            right: -11px;
            bottom: -34px;
            width: 54px;
            height: 53px;
            background: url(./page/league/img/arrow.png) center center no-repeat;
            background-size: cover;
          }
        }
      }

    .step_wrap {
      &.step2 ol {
        margin: 0 39px 39px;
        overflow: hidden;

        li {
          float: left;
          margin-right: 28px;
          width: calc(50% - 14px);

          &:nth-child(even) {
            margin-right: 0;
          }

          > div > {
            p {
              margin: 39px 0 14px;
            }

            img {
              border: 1px solid #342b20;
              width: 100%;
              -webkit-box-sizing: border-box;
              box-sizing: border-box;
            }
          }
        }
      }

      &.step3 {
        > img {
          margin: 58px 0 12px 0;
        }

        p {
          font-size: 16px;
          margin-bottom: 58px;
        }
      }
    }   
  }
} 

.download-page .section3 .text {
  margin: 1em 0;
  font-size: 16px;
  color: #d4c6a9;
  line-height: 26px;
}

.download-page {
  @media screen and (max-width: 980px) {
    .d_section .sec1_cont {
      margin: 0 20px;
    }
  }

  @media screen and (max-width: 640px) {
    .pc_show {
      display: none !important;
    }

    .mobile_show {
      display: block;
    }

    .d_head {
      height: 107px;
      height: 33.4375vw;

      h2 {
        bottom: auto;
        top: 15px;
        top: 4.6875vw;
        font-size: 22px;
        font-size: 6.875vw;
        left: 10px;
        left: 3.125vw;
        letter-spacing: -0.5px;

        span {
          float: none;
        }

        .eng_title {
          margin-left: 0;
          margin-top: 5px;
          margin-top: 1.5625vw;
          font-size: 9px;
          font-size: 2.8125vw;
          letter-spacing: 2px;
          display: block;
        }
      }
    }

    .d_section {
      .sec1_cont h4 {
        font-size: 18px;
        font-size: 5.625vw;
      }

      &.section1 {
        .sec1_top {
          height: auto;

          h3 {
            font-size: 20px;
            font-size: 6.25vw;
            line-height: 23px;
            line-height: 7.1875vw;
            margin-bottom: 5px;
            margin-bottom: 1.5625vw;
          }

          p {
            font-size: 12px;
            font-size: 3.75vw;
            line-height: 17px;
            line-height: 5.3125vw;
            margin-bottom: 16px;
            margin-bottom: 5vw;
          }

          a {
            display: none;
          }
        }

        .sec1_cont.sc_table .sec1_contInner {
          table tbody tr td {
            text-align: left;
            padding-left: 10px;
            padding-right: 10px;
          }

          &.pc {
            display: none;
          }

          &.mo {
            display: block;
  
            table:nth-child(1) {
              margin-bottom: 30px;
              margin-bottom: 9.375vw;
            }
          }
        }
      }

      &.section2 .sec1_cont > ol > li {
        > p {
          overflow: hidden;
          font-size: 12px;
          font-size: 3.75vw;

          span {
            display: table-cell;
            vertical-align: top;

            &.num {
              font-size: 19px;
              font-size: 5.9375vw;
            }

            &.text {
              padding-left: 5px;
              padding-left: 1.5625vw;
              line-height: 17px;
              line-height: 5.3125vw;
            }
          }
        }

        .step_wrap {
          &.step1 {
            padding: 0 0 1.67em;
            height: auto;

            h5 {
              font-size: 18px;
              font-size: 5.625vw;
            }

            p {
              font-size: 12px;
              font-size: 3.75vw;
              line-height: 17px;
              line-height: 5.3125vw;
              margin-bottom: 16px;
              margin-bottom: 5vw;
            }

            button {
              font-size: 12px;
              font-size: 3.75vw;
              line-height: 33px;
              line-height: 10.3125vw;
              width: 120px;
              width: 37.5vw;
              height: 33px;
              height: 10.3125vw;
              margin-bottom: 35px;
              margin-bottom: 10.9375vw;

              &::after {
                width: 32px;
                width: 10vw;
                height: 31.5px;
                height: 9.84375vw;
                bottom: -21.5px;
                bottom: -6.71875vw;
                right: -3.5px;
                right: -1.09375vw;
              }
            }
          }

          &.step2 ol {
            margin: 0;

            li {
              width: 100%;
              float: none;
              margin-right: 0;
              border-bottom: 1px solid #342b20;
              padding-bottom: 20px;
              padding-bottom: 6.25vw;

              &:nth-last-child(1) {
                border-bottom: 0;
              }

              > div {
                margin-left: 20px;
                margin-left: 6.25vw;
                margin-right: 20px;
                margin-right: 6.25vw;

                > p {
                  font-size: 12px;
                  font-size: 3.75vw;
                  margin-top: 20px;
                  margin-top: 6.25vw;
                }
              }
            }
          }
        }
      }
    }
  }
}
