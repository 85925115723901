body {
  font-family: 'RixSGoM', '나눔 고딕', Dotum, sans-serif;
  font-size: 14px;
  color: #978e79;
}

ol,
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.hidden {
  display: none;
}
