.league {
// .os-navigator-container {
  .os-navigator {
    float: right;
    margin-top: 48px;
  
    a {
      display: inline-block;
      margin: 0 0 1em 1em;
      height: 36px;
      opacity: 0.3;
  
      img {
        height: 100%;
      }
  
      &.active {
        opacity: 1;
      }
    }

    @media screen and (max-width: 980px) {
      margin-right: 20px;
    }
    @media screen and (max-width: 640px) {
      float: none;
      text-align: center;
    }
  }
  // }

  @media screen and (max-width: 640px) {
    .mobile_show {
      overflow: hidden;
      background: url(./img/download-banner-mobile.jpg) center right no-repeat;
      background-size: cover;
      position: relative;
    }    
  }  
}